import React from "react"
import { graphql} from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import Hero from "../components/HeroTwo"
import SEO from '../components/SEO'

const Template = ({ data }) => {
  const { body: content } = data.blog.body;
  const {title} = data.blog;
  const {heroImage} = data.blog;
  const {description} = data.blog.description;
  
  return (
    <Layout>
      <SEO title = {title} description = {description} />
      <Hero blog = {true} image = {heroImage.fluid} content = {title}/>
      <BlogTemplate>
        <BlogCenter>
          <article>
            {<ReactMarkdown source={content}></ReactMarkdown>}
          </article>
        </BlogCenter>
      </BlogTemplate>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String, $locale: String) {
  blog: contentfulBlogPost(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    title
    description {
      description
    }
    body {
      body
    }
    heroImage {
      fluid(maxWidth: 2048, quality: 100) {
        src
      }
      node_locale
    }
  }
}
`

const BlogTemplate = styled.section`
  padding: 5rem 0;
  h1,
  h2 {
    color: royalblue;
    text-align: center;
  }
  ul {
    margin-top: 2rem;
    display: inline-block;
    color: ${({ theme }) => theme.colorGray8};
  }
  ul li {
    background: ${({ theme }) => theme.colorGrey9};
    color: ${({ theme }) => theme.colorGrey5};
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    text-transform: uppercase;
  }
  blockquote {
    background: ${({ theme }) => theme.colorPrimary9};
    border-radius: ${({ theme }) => theme.radius};
    padding: 1rem;
    line-height: 2;
    color: v ${({ theme }) => theme.colorPrimary5};
    margin: 2rem 0;
  }
  pre {
    background: #222;
    color: yellow;
    overflow-x: scroll;
    padding: 1rem 1.5rem;
    border-radius: ${({ theme }) => theme.radius};
  }
  img {
    width: 100%;
    margin: 3rem 0;
  }
  .Image {
    width: 100%;
    margin: 3rem 0;
  }
`

const BlogCenter = styled.div`
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  @media screen and (min-width: 992px) {
    width: 95vw;
  }
`
export default Template